import { GET_CUSTOMER_MESSAGES } from "../../store/types";

export default {
  methods: {
    getCustomerMessages(page = 1, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_CUSTOMER_MESSAGES, page)
        .then(response=>{
          resolve(response);
          busy && this.hideBusy();
        }).catch(response=>{
          reject(response);
          busy && this.hideBusy();
        })
      })
    }
  }
}
