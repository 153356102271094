<template>
  <div class="history-list">
    <error :errors="customerError" />
    <b-overlay :show="formBusy">
      <portlet title="ئۇچۇر يوللاش خاتىرىسى" >
        <template v-slot:toolbar>
          <b-row class="align-items-center d-none d-sm-flex">
            <b-col md="2" sm="2" >
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm ">
                يېڭىلاش
              </button>
            </b-col>
          </b-row>
        </template>
        <template v-slot:body>
          <b-row class="m-t-2">
            <b-col cols="12">
              <b-table :show-empty="true" id="messageList" :busy="formBusy" :fields="tableFields" :items="customerMessages">
                <template v-slot:empty="scope">
                  <p class="text-center"> ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن . </p>
                </template>
                <template v-slot:cell(message)="row">
                  <p v-if="row.item.message === 'text'">{{ row.item.source.content }}</p>
                  <p v-if="row.item.message === 'news'"><a target="_blank" :href="row.item.source.url">{{ row.item.source.title }}</a></p>
                  <p v-if="row.item.message === 'image'"><b-img-lazy height="60px" :src="asset(row.item.source.image)" /></p>
                </template>
                <template v-slot:cell(start_time)="row">
                  <p dir="ltr">{{ row.item.start_time }}</p>
                </template>
                <template v-slot:cell(updated_at)="row">
                  <p dir="ltr">{{ row.item.updated_at }}</p>
                </template>
                <template v-slot:cell(process)="row">
                  <template v-if="row.item.count">
                    <b-progress :max="100" :striped="((row.item.count / row.item.total) * 100) < 95" :animated="((row.item.count / row.item.total) * 100) < 95">
                      <b-progress-bar :value="(row.item.count / row.item.total) * 100" :label="`${row.item.total} / ${row.item.count}`" />
                    </b-progress>
                  </template>
                  <template v-else>
                    باشلانمىدى
                  </template>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-dropdown toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                    <template v-slot:button-content>
                      <i class="flaticon-more-1"></i>
                    </template>
                    <b-dropdown-item>
                      يوق
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <b-pagination
                      v-if="customerMessagesMeta.total"
                      class="my-2"
                      v-model="customerMessagesMeta.current_page"
                      :total-rows="customerMessagesMeta.total"
                      :per-page="customerMessagesMeta.per_page"
                      aria-controls="messageList"
                      @input="onChangePage"
              />
            </b-col>
          </b-row>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../../../partials/content/Portlet";
  import Error from "../../../../partials/widgets/Error";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import customerMixin from "../../../../../mixins/weChat/customerMessage.mixin";
  import { mapGetters } from "vuex";
  import {Datetime} from "vue-datetime";
  import { asset } from "../../../../../common/helpers/helpers";

  export default {
    name: "History",
    components: { Error, Portlet, Datetime },
    mixins: [ formBusyMixin, customerMixin ],
    computed: {
      ...mapGetters(['customerError', 'customerMessages', 'customerMessagesMeta', 'customerMessagesPage'])
    },
    created() {
      this.getCustomerMessages(this.customerMessagesPage);
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "list" },
        { title: "سالون ئاكتىپ ئەزالىرى" },
        { title: "ئۇچۇر يوللاش خاتىرىسى" }
      ]);
    },
    data(){
      return {
        formBusy: false,
        tableFields: [
          { key: "id", label: "id", class: ['d-none', 'd-md-table-cell'] },
          { key: "message", label: "مەزمۇنى", class: ['d-none', 'd-md-table-cell'] },
          { key: "process", label: "ئىجرا ھالىتى" },
          { key: "start_time", label: "باشلىنىش ۋاقتى", class: ['d-none', 'd-md-table-cell']},
          { key: "updated_at", label: "ئاخىرلاشقان ۋاقتى", class: ['d-none', 'd-md-table-cell']},
          { key: "actions", label: "مەشغۇلات" }
        ],
      }
    },
    methods: {
      refreshList(){
        this.getCustomerMessages(this.customerMessagesPage);
      },
      onChangePage(page = 1){
        this.getCustomerMessages(page);
      },
      asset(src){
        return asset(src);
      }
    }
  }
</script>

<style scoped>

</style>
